
import NothingOnInspectMode from '@/provider/NohingOnInspectMode.vue'
import { TradeDirection } from '@/types'
import { computed, ref, defineComponent } from 'vue'
import { AnalysisSchema, readDetail } from '@/modules/community/analysis/analysis.api'
import * as R from 'ramda'
import { useRouter } from 'vue-router'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Icon from '@/components/Icon.vue'
import Time from '@/components/Time.vue'
import Line from '@/components/Line.vue'

export default defineComponent({
  name: 'Advice',
  components: { NothingOnInspectMode, Line, PageWithHeader, Icon, Time },
  props: {
    id: String,
  },
  setup (props) {
    const advice = ref<AnalysisSchema>({} as AnalysisSchema)

    const isPositive = computed(() => {
      if (advice.value) return R.propEq('direction', TradeDirection.BUY, advice.value)
      return false
    })

    const fail = () => {
      useRouter().back()
    }

    const updateAdvice = (resp: AnalysisSchema) => {
      advice.value = resp
    }

    if (props.id) {
      readDetail({ id: props.id }).then(updateAdvice).catch(fail)
    }

    return {
      advice,
      isPositive,
    }
  },
})
