<template>
  <PageWithHeader>
    <template #title><t path="analysis_2">Analysis</t></template>
    <div class="p-16">
      <div class="df-middle">
        <Icon class="p-0" :class="{'c-success': isPositive, 'c-danger': !isPositive}" sm
              :name=" isPositive ? 'up': 'down'" />
        <span class="f-lg f-bold ml-16">{{advice.code}}</span>
        <div class="flex-1 t-right">
          <NothingOnInspectMode>
            <router-link
              :to="`/symbol/${advice.code}`"
              class="btn sm round d-ib primary f-bold px-12"
              style="line-height: 32px;"
            >
              <t path="analysis_3">Trade Now</t>
            </router-link>
          </NothingOnInspectMode>
        </div>
      </div>
      <p class="mt-16 f-medium" :class="{'c-success': isPositive, 'c-danger': !isPositive}">
        {{advice.informactionContent}}
      </p>
      <Time tag="p" class="t-right c-tip f-sm"
            format="MM-dd-yy HH:mm:ss"
            :time="advice.createTime"
      />
    </div>
    <div class="px-16 advice-line">
      <Line />
    </div>
    <div class="p-16 f-nm">
      <p class="f-bold f-lg mb-16">{{advice.informactionAbstract}}</p>
      <p>{{advice.contentTitle}}</p>
      <p class="f-bold f-lg mt-24 mb-4">1D Chart</p>
      <img :src="advice.imgDay" style="width: 100%;" />
      <p class="my-8">{{advice.contentDay}}</p>
      <p class="f-bold f-lg mt-24 mb-4">4H Chart</p>
      <img :src="advice.imgHour" style="width: 100%;" />
      <p class="mt-8">{{advice.contentHour}}</p>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import NothingOnInspectMode from '@/provider/NohingOnInspectMode.vue'
import { TradeDirection } from '@/types'
import { computed, ref, defineComponent } from 'vue'
import { AnalysisSchema, readDetail } from '@/modules/community/analysis/analysis.api'
import * as R from 'ramda'
import { useRouter } from 'vue-router'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Icon from '@/components/Icon.vue'
import Time from '@/components/Time.vue'
import Line from '@/components/Line.vue'

export default defineComponent({
  name: 'Advice',
  components: { NothingOnInspectMode, Line, PageWithHeader, Icon, Time },
  props: {
    id: String,
  },
  setup (props) {
    const advice = ref<AnalysisSchema>({} as AnalysisSchema)

    const isPositive = computed(() => {
      if (advice.value) return R.propEq('direction', TradeDirection.BUY, advice.value)
      return false
    })

    const fail = () => {
      useRouter().back()
    }

    const updateAdvice = (resp: AnalysisSchema) => {
      advice.value = resp
    }

    if (props.id) {
      readDetail({ id: props.id }).then(updateAdvice).catch(fail)
    }

    return {
      advice,
      isPositive,
    }
  },
})
</script>
<style scoped lang="scss">
.advice-line .line::after {
  border-color: var(--color-grey);
}
</style>
